@import url(/css/ventunoFont.css);
@font-face {
  font-family: "babylove";
  src: url("/css/Babylove-zGwX.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*@font-face { 
	font-family: 'VAG';
	font-style: normal;
	font-weight: 900;
	src: url('/css/VAG Rounded Black_0.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 700;
	src: url('/css/VAG Rounded Bold_0.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 400;
	src: url('/css/VAG Rounded Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'VAG';
	font-style: normal;
	font-weight: 100;
	src: url('/css/VAG Rounded Thin_0.ttf') format('truetype');
}

@font-face {
	font-family: 'VAG BT';
	font-style: normal;
	font-weight: 100 900;
	src: url('/css/VAGRounded_BT.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	src: url('/css/Helvetica.ttc') format('truetype');
}*/
body {
  margin: 0;
  padding: 0 !important;
  background-color: var(--background) !important;
  background-image: var(--background-image);
  background-position-x: center;
  background-position-y: center;
  background-size: auto;
  background-repeat-x: no-repeat;
  background-repeat-y: repeat;
  background-attachment: fixed;
  background-origin: initial;
  background-clip: initial;
  font-family: "Montserrat", "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767px) {
  body {
    background-image: var(--background-image-sm);
  }
}
.v-widget {
  margin-top: 20px;
}

.v-card {
  border: 0px;
  background-color: inherit;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.cursor-pointer {
  cursor: pointer;
}

h2 {
  font-size: 1.6rem;
}

.widget-title {
  color: var(--accent-color);
}
.widget-title.title-center {
  text-align: center;
}
.widget-title.title-right {
  text-align: right;
}
.widget-title h2 {
  font-size: 1.2rem;
  font-family: "Montserrat", "Roboto", sans-serif;
}
.widget-title.babylove h2 {
  font-family: babylove, sans-serif;
  letter-spacing: 0.15rem;
  font-size: 1.5rem;
}
.widget-title h2 {
  font-size: 1.2rem;
}
.widget-title h3 {
  font-size: 1.1rem;
}
.widget-title h4 {
  font-size: 1rem;
}
.widget-title.ttl-size-small h2 {
  font-size: 0.9rem;
}
.widget-title.ttl-size-small h3 {
  font-size: 0.8rem;
}
.widget-title.ttl-size-small h4 {
  font-size: 0.7rem;
}
.widget-title.ttl-size-large h2 {
  font-size: 1.55rem;
}
.widget-title.ttl-size-large h3 {
  font-size: 1.45rem;
}
.widget-title.ttl-size-large h4 {
  font-size: 1.35rem;
}

.text-accent {
  color: var(--accent-color);
}

a,
a:hover {
  text-decoration: none;
  color: var(--accent-color);
}

a:hover {
  text-decoration: underline;
}

input::-ms-clear {
  display: none;
}

.vt-select {
  height: unset;
  padding: 5px 15px !important;
}

.vt-input, .vt-select {
  border-radius: 0;
  padding: 15px;
  font-size: 0.8rem;
  font-family: "Montserrat", "Roboto", sans-serif;
}
@media screen and (min-width: 768px) {
  .vt-input, .vt-select {
    padding: 15px;
    height: 52px;
    line-height: 24px;
    font-size: 1rem;
  }
}

.vt-description {
  color: var(--text-primary-a70);
}

.vt-btn-like {
  cursor: pointer;
}

.vt-btn {
  font-family: "Montserrat", "Roboto", sans-serif;
  border-radius: 100px;
  font-weight: 600;
  border: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  min-width: 80px;
  font-size: 0.6rem;
  margin: 0px 5px;
  padding: 0.6rem 1.1rem;
  letter-spacing: 0.03rem;
  line-height: 1;
}
.vt-btn.no-link {
  cursor: not-allowed !important;
}
.vt-btn.icon-btn {
  padding-left: 2.2rem;
}
.vt-btn.icon-btn svg {
  fill: var(--text-primary);
  width: 2rem;
  position: absolute;
  top: 1.73rem;
  left: 0.36rem;
}
.vt-btn.btn-sm {
  font-size: 0.6rem;
  padding: 0.5rem 1rem;
  letter-spacing: 0rem;
  min-width: 70px;
  margin: 0px 3px;
}
.vt-btn.btn-sm.icon-btn {
  padding-left: 1.3rem;
}
.vt-btn.btn-sm.icon-btn svg {
  width: 0.8rem;
  top: 0.35rem;
  left: 0.6rem;
}
@media screen and (min-width: 768px) {
  .vt-btn {
    min-width: 90px;
    font-size: 0.7rem;
    margin: 0px 10px;
    padding: 0.7rem 1.2rem;
  }
  .vt-btn.icon-btn {
    padding-left: 2.3rem;
  }
}
@media screen and (max-width: 992px) {
  .vt-btn.icon-btn svg {
    top: 1.58rem;
  }
}
.vt-btn.vt-btn-danger {
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
}

.no-border-radius {
  border-radius: 0;
  color: var(--botton-text-color) !important;
  background-color: var(--botton-color);
}
.no-border-radius:hover {
  background-color: var(--botton-hover-color);
}
.no-border-radius[disabled] {
  background-color: var(--botton-disabled-color);
}

.vt-btn-primary {
  color: var(--botton-text-color) !important;
  background-color: var(--botton-color);
}
.vt-btn-primary:hover {
  background-color: var(--botton-hover-color);
}
.vt-btn-primary[disabled] {
  background-color: var(--botton-disabled-color);
}
.vt-btn-primary.btn-with-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vt-btn-primary.btn-with-loader > * {
  margin-right: 1rem;
}

.vt-btn-secondary {
  color: var(--text-primary) !important;
  background-color: var(--text-secondary);
}
.vt-btn-secondary:hover {
  background-color: var(--text-secondary-d15);
}
.vt-btn-secondary[disabled] {
  background-color: var(--text-secondary-d30);
}
@media screen and (min-width: 768px) {
  .vt-btn-secondary.vt-btn-icon {
    padding-left: 2.2rem;
  }
  .vt-btn-secondary.vt-btn-icon svg {
    position: absolute;
    top: 0.45rem;
    left: 1.2rem;
    width: 24px;
    height: 24px;
  }
}
.vt-btn-secondary.btn-with-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vt-btn-secondary.btn-with-loader > * {
  margin-right: 1rem;
}

.vt-btn-tertiary {
  background-color: transparent;
  color: var(--text-primary);
}
.vt-btn-tertiary svg {
  width: 24px;
  height: 24px;
  fill: var(--text-primary);
  margin-right: 0.6rem;
}
.vt-btn-tertiary:hover {
  background-color: var(--text-primary-a10);
  color: var(--text-primary);
}

.vt-btn-primary.vt-btn-icon,
.vt-btn-secondary.vt-btn-icon {
  padding-left: 2.2rem;
}
.vt-btn-primary.vt-btn-icon svg,
.vt-btn-secondary.vt-btn-icon svg {
  position: absolute;
  top: 0.5rem;
  left: 0.7rem;
  width: 18px;
  height: 18px;
  fill: var(--text-primary);
}
@media screen and (max-width: 768px) {
  .vt-btn-primary.vt-btn-icon,
  .vt-btn-secondary.vt-btn-icon {
    padding-left: 1.9rem;
  }
  .vt-btn-primary.vt-btn-icon svg,
  .vt-btn-secondary.vt-btn-icon svg {
    top: 0.45rem;
    left: 0.6rem;
    width: 14px;
    height: 14px;
  }
}

.vt-icon {
  position: relative;
  width: 51px;
  height: 51px;
  min-width: 51px;
  min-height: 51px;
  padding: 0;
}
.vt-icon div {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 12px;
  left: 12px;
}
.vt-icon div svg {
  fill: var(--text-primary);
}
@media screen and (max-width: 768px) {
  .vt-icon {
    padding-left: 46px;
  }
  .vt-icon div {
    width: 20px;
    height: 20px;
    top: 8px;
    left: 18px;
  }
}

.vt-btn-link {
  color: var(--accent-color) !important;
  background-color: transparent;
  font-size: 1rem;
}
.vt-btn-link:hover {
  color: var(--accent-color-l25);
}
@media screen and (max-width: 768px) {
  .vt-btn-link {
    font-size: 0.7rem;
  }
}

input[type=email],
input[type=text],
input[type=password] {
  border-radius: 0;
}

.text-accent {
  color: var(--accent-color) !important;
}

.text-primary {
  color: var(--text-primary) !important;
}

.text-secondary {
  color: var(--text-secondary) !important;
}

.vt-modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.vt-modal .social-share-popup {
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .vt-modal .social-share-popup {
    max-width: 50%;
    margin-left: auto;
  }
}
@media screen and (min-width: 992px) {
  .vt-modal .social-share-popup {
    max-width: 30%;
  }
}
.vt-modal .social-share-popup .modal-content {
  min-height: 10em;
}
.vt-modal .social-share-popup .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-title .title-center {
  text-align: center;
}
.widget-title .title-right {
  text-align: right;
}

.form-help-text {
  color: var(--text-secondary);
  margin: 0.4rem 0rem;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.background-seo-element {
  display: none;
  visibility: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.v-card {
  background-color: inherit;
}
.vtn-cover-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.5s linear;
}
.vtn-cover-screen.vt-vis {
  transition: opacity 0.5s linear 0.3s;
  opacity: 1;
}
.limited-viewing-popup {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
}
.limited-viewing-popup .message-box {
  width: 90%;
  max-width: 500px;
  height: 90%;
  max-height: calc(9 / 16 * 500px);
  background-color: var(--background-a50);
  border: 1px solid var(--text-secondary-a50);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.limited-viewing-popup .message-box img {
  width: 100%;
}
.limited-viewing-popup .message-box .text-wrp {
  width: 100%;
}
.limited-viewing-popup .message-box h2 {
  margin-top: 20px;
  color: var(--text-primary);
  margin-bottom: 40px;
  font-size: 1.2rem;
  padding: 0 5%;
}
.limited-viewing-popup .message-box .btns {
  height: 40px;
  margin-bottom: 15px;
  width: 100%;
}
.limited-viewing-popup .message-box .btns .btn {
  margin-left: 10px;
}
@media screen and (max-width: 992px) {
  .limited-viewing-popup .message-box h2 {
    font-size: 1.1rem;
    margin-bottom: 35px;
  }
  .limited-viewing-popup .message-box .btns {
    height: 37px;
  }
  .limited-viewing-popup .message-box .btns .btn {
    margin-left: 9px;
  }
}
@media screen and (max-width: 768px) {
  .limited-viewing-popup .message-box h2 {
    font-size: 0.95rem;
    margin-bottom: 25px;
  }
  .limited-viewing-popup .message-box .btns {
    height: 34px;
  }
  .limited-viewing-popup .message-box .btns .btn {
    margin-left: 8px;
  }
}
@media screen and (max-width: 576px) {
  .limited-viewing-popup .message-box h2 {
    font-size: 0.75rem;
    margin-bottom: 20px;
  }
  .limited-viewing-popup .message-box .btns {
    height: 31px;
  }
  .limited-viewing-popup .message-box .btns .btn {
    margin-left: 7px;
  }
}
.ventuno-player {
  position: relative;
  width: 100%;
  padding: 0;
  background-color: black;
  z-index: auto;
}
.ventuno-player .player-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  background-color: black;
}
.ventuno-player .anim-transition {
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, top 0.3s ease-in-out, left 0.3s ease-in-out;
}
.ventuno-player .side-pannel {
  position: absolute;
  top: 0;
  display: none;
  width: 0;
  left: 100%;
}
.ventuno-player .side-pannel.show {
  display: block;
}
.ventuno-player.landscape .side-pannel.show {
  transition: width 0.4s ease-in-out, left 0.4s ease-in-out;
}
.ventuno-player.landscape .side-pannel.expand {
  width: 20%;
  left: 80%;
}
.ventuno-player.landscape .player-wrapper.show-sp {
  transition: width 0.4s ease-in-out;
}
.ventuno-player.landscape .player-wrapper.expand-sp {
  width: 80%;
}
@media screen and (max-width: 1200px) {
  .ventuno-player.landscape .side-pannel.expand {
    width: 25%;
    left: 75%;
  }
  .ventuno-player.landscape .player-wrapper.expand-sp {
    width: 75%;
  }
}
@media screen and (max-width: 992px) {
  .ventuno-player.landscape .side-pannel.expand {
    width: 30%;
    left: 70%;
  }
  .ventuno-player.landscape .player-wrapper.expand-sp {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .ventuno-player.landscape .side-pannel.expand {
    width: 35%;
    left: 65%;
  }
  .ventuno-player.landscape .player-wrapper.expand-sp {
    width: 65%;
  }
}
@media screen and (max-width: 576px) {
  .ventuno-player.landscape .side-pannel.expand {
    width: 40%;
    left: 60%;
  }
  .ventuno-player.landscape .player-wrapper.expand-sp {
    width: 60%;
  }
}
.ventuno-player.portrait .player-wrapper.show-sp,
.ventuno-player.portrait .side-pannel {
  width: 100%;
  left: 0%;
}
.ventuno-player.portrait .side-pannel.show {
  height: 0;
  top: 100%;
  transition: height 0.4s ease-in-out, top 0.4s ease-in-out;
}
.ventuno-player.portrait .player-wrapper.show-sp {
  transition: height 0.4s ease-in-out;
}
.ventuno-player .top-pannel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  display: none;
}
.ventuno-player .top-pannel .close-button {
  position: absolute;
  top: 0.67rem;
  left: 0.5rem;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
}
.ventuno-player .top-pannel .close-button svg {
  fill: white;
  width: 100%;
  height: 100%;
}
@media (max-width: 400px) {
  .ventuno-player .top-pannel .close-button {
    width: 1.9rem;
    height: 1.9rem;
    top: 0.67rem;
    left: 0.5rem;
  }
}
.ventuno-player .comment-box-toggle {
  position: absolute;
  right: 2rem;
  bottom: 4rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-color: var(--accent-color);
  cursor: pointer;
}
.ventuno-player .comment-box-toggle svg {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  fill: var(--text-primary);
}
.video-comments {
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
}
.video-comments::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: inherit;
}
.video-comments::-webkit-scrollbar {
  width: 5px;
  background-color: inherit;
}
.video-comments::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
}
.video-comments .video-message {
  min-height: 50px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  display: flex;
}
.video-comments .video-message .profile-container {
  padding-right: 0.5rem;
  padding-top: 4px;
}
.video-comments .video-message .profile-container .profile-image {
  min-width: 1.4rem;
  min-height: 1.4rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.video-comments .video-message .user-pic {
  padding-top: 5px;
  position: relative;
}
@media screen and (max-width: 992px) and (min-width: 576px) {
  .video-comments .video-message .user-pic {
    width: 1rem;
    margin-right: 2%;
  }
}
.video-comments .video-message .user-pic .user-box {
  min-width: 1.4rem;
  min-height: 1.4rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  height: 0;
  padding-top: 100%;
  background-color: var(--botton-color);
  border-radius: 50%;
  position: relative;
}
.video-comments .video-message .user-pic .user-box svg {
  position: absolute;
  top: 18%;
  left: 25%;
  width: 60%;
  height: 60%;
  fill: var(--botton-text-color);
}
.video-comments .video-message .msg-area {
  width: 100%;
  position: relative;
  padding: 4px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 992px) and (min-width: 576px) {
  .video-comments .video-message .msg-area {
    padding-left: 10px;
  }
}
.video-comments .video-message .msg-area .wrapper-info {
  padding: 10px 30px 10px 15px;
  background-color: var(--accent-color);
  border-radius: 0px 10px 10px 10px;
  width: 100%;
}
.video-comments .video-message .msg-area .wrapper-info .msg-name {
  width: 100%;
  min-width: 5rem;
  font-size: 0.9rem;
  font-weight: 600;
  line-break: anywhere;
  color: var(--botton-text-color);
  margin-bottom: 8px;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.video-comments .video-message .msg-area .wrapper-info .msg-text {
  padding-right: 5px;
  font-size: 0.75rem;
  color: var(--text-primary);
  line-break: anywhere;
}
.video-comments .video-message .msg-area .msg-time {
  color: var(--text-primary);
  font-size: 0.65rem;
  padding-left: 0.4rem;
}
.video-comments .video-message.self-comments {
  justify-content: end;
}
.video-comments .video-message.self-comments .msg-area {
  text-align: right;
}
.video-comments .video-message.self-comments .msg-area .wrapper-info {
  padding: 10px 15px 10px 30px;
  border-radius: 10px 0px 10px 10px;
  background-color: var(--surface-secondary);
}
@media screen and (max-width: 992px) and (min-width: 576px) {
  .video-comments .video-message.self-comments .msg-area {
    padding-left: 15px;
  }
}
.comment-field {
  position: relative;
  height: 100%;
}
.comment-field .form-loader-wrapper {
  height: 100%;
  position: relative;
}
.comment-field .form-loader-wrapper .loader-bg {
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  background-color: var(--background-a70);
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comment-field .form-loader-wrapper .loader-bg p {
  margin-left: 0.5rem;
  color: var(--accent-color);
  margin-top: 0.8rem;
}
.comment-field .header-box {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  padding: 4px;
  background-color: var(--background);
}
@media screen and (max-width: 992px) {
  .comment-field .header-box {
    padding-top: 5px;
    margin-bottom: 13px;
  }
}
.comment-field .header-box input {
  width: 80%;
  background: none;
  border: none;
  outline: none;
  padding: 4px;
  padding-right: 1000px;
  color: var(--text-primary);
  font-size: 0.9rem;
  height: 30px;
}
@media screen and (max-width: 1200px) {
  .comment-field .header-box input {
    width: 83%;
  }
}
@media screen and (max-width: 992px) {
  .comment-field .header-box input {
    width: 90%;
  }
}
.comment-field .header-box .emoji-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 4px;
  right: 30px;
  cursor: pointer;
  font-size: 1rem;
  padding-top: 3px;
  padding-left: 5px;
}
.comment-field .header-box .user-box {
  width: 20px;
  height: 20px;
  margin: 3px 9px 0 6px;
  background-color: var(--botton-color);
  border-radius: 50%;
  position: relative;
}
.comment-field .header-box .user-box svg {
  position: absolute;
  top: 18%;
  left: 25%;
  width: 60%;
  height: 60%;
  fill: var(--botton-text-color);
}
.comment-field .header-box button {
  position: absolute;
  right: 8px;
  padding-right: 3px;
  background: none;
  border: none;
  font-family: "Poppins", sans-serif !important;
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.6;
  cursor: pointer;
}
.comment-field .header-box button span {
  margin-right: 6px;
}
.comment-field .header-box svg {
  width: 20px;
  fill: var(--text-primary);
}
.spinner-loader {
  border: 0.3rem solid transparent;
  border-top: 0.2rem solid var(--accent-color);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  -webkit-animation: spinnerAnimation 1.5s infinite;
          animation: spinnerAnimation 1.5s infinite;
}
.spinner-loader.sm {
  width: 1.5rem;
  height: 1.5rem;
}
.spinner-loader.xs {
  width: 0.7rem;
  height: 0.7rem;
  border-right: 0.2rem solid var(--accent-color);
}
.spinner-loader.onPrimary {
  border-top-color: var(--text-primary);
  border-right-color: var(--text-primary);
}

@-webkit-keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.comment-box {
  width: 100%;
  max-height: 100%;
  min-height: 98%;
  position: relative;
}
@media screen and (max-width: 992px) {
  .comment-box {
    height: 300px;
    margin-top: 1.1rem;
  }
}
@media screen and (max-width: 576px) {
  .comment-box {
    margin-top: 0.8rem;
  }
}
.comment-box .box-header {
  height: 40px;
  border-bottom: 1px solid var(--text-secondary-a50);
  position: relative;
  z-index: 1;
}
.comment-box .box-header .box-title {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: var(--text-primary);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 10px;
  background-color: var(--surface-secondary);
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.9rem;
  font-weight: 600;
  min-width: 90px;
}
.comment-box .box-header .switch-text {
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--text-secondary);
  position: absolute;
  right: 5px;
  top: 15px;
  cursor: pointer;
}
.comment-box.full-screen {
  min-height: 100%;
  margin-top: 0;
}
.comment-box.full-screen .box-body {
  padding: 0;
}
.comment-box.full-screen .box-body .show-comment {
  display: flex;
  flex-flow: column;
  padding-top: 0;
}
.comment-box.full-screen .box-body .show-comment .comment-list {
  height: 0;
  flex: 1 1;
}
.comment-box.full-screen .box-body .show-comment .comment-field-wrpr {
  height: 2.29rem;
}
.comment-box .box-body {
  position: absolute;
  padding: 48px 0 8px;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .comment-box .box-body {
    padding: 45px 5px 5px;
  }
}
.comment-box .box-body .show-comment,
.comment-box .box-body .hidden-comment {
  position: relative;
  width: 100%;
  height: 100%;
}
.comment-box .box-body .hidden-comment .comments-icon {
  width: 15%;
  height: 15%;
  fill: var(--text-secondary-a50);
  position: absolute;
  left: calc((100 - 15%) / 2);
  top: calc((100 - 15%) / 2 - 10);
}
@media screen and (max-width: 992px) {
  .comment-box .box-body .hidden-comment .comments-icon {
    width: 8%;
    height: 8%;
    left: calc((100 - 8%) / 2);
    top: calc((100 - 8%) / 2 - 10);
  }
}
.comment-box .box-body .hidden-comment .no-comments {
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--text-secondary);
  position: absolute;
  width: 100%;
  text-align: center;
  top: calc((100 - 15%) / 2 + 15);
}
@media screen and (max-width: 992px) {
  .comment-box .box-body .hidden-comment .no-comments {
    top: calc((100 - 8%) / 2 + 15);
  }
}
@media screen and (max-width: 768px) {
  .comment-box .box-body .hidden-comment .no-comments {
    top: calc((100 - 8%) / 2 + 10);
  }
}
@media screen and (max-width: 576px) {
  .comment-box .box-body .hidden-comment .no-comments {
    top: calc((100 - 8%) / 2 + 8);
  }
}
.comment-box .box-body .show-comment {
  padding-top: 0.1rem;
}
.comment-box .box-body .show-comment .comment-list {
  height: 92%;
  margin-bottom: 2%;
}
.comment-box .box-body .show-comment .comment-field-wrpr {
  position: relative;
  height: 8%;
  background-color: var(--surface-secondary);
}
@media screen and (max-width: 1200px) {
  .comment-box .box-body .show-comment .comment-list {
    height: 88%;
    margin-bottom: 1.5%;
  }
  .comment-box .box-body .show-comment .comment-field-wrpr {
    height: 12%;
  }
}
@media screen and (max-width: 992px) {
  .comment-box .box-body .show-comment .comment-list {
    height: 85%;
    margin-bottom: 0.5%;
  }
  .comment-box .box-body .show-comment .comment-field-wrpr {
    height: 15%;
  }
}
.comment-box .box-body .show-comment input[type=text] {
  padding: 0 0.3rem;
}
.comment-box-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}
.comment-box-wrapper .top-box-pannel {
  border-bottom: 1px solid var(--accent-color-a70);
  width: 100%;
  height: 2rem;
}
.comment-box-wrapper .top-box-pannel .close-btn {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  top: 0.3rem;
  right: 0.3rem;
  cursor: pointer;
  line-height: 0;
}
.comment-box-wrapper .top-box-pannel .close-btn svg {
  width: 100%;
  height: 100%;
  fill: var(--text-secondary);
}
.comment-box-wrapper .top-box-pannel .box-title {
  line-height: 1;
  font-weight: 400;
  color: var(--text-primary);
  position: absolute;
  top: 0.42rem;
  left: 1rem;
  padding-left: 1.3rem;
}
.comment-box-wrapper .top-box-pannel .box-title .red-dot {
  position: absolute;
  background-color: red;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  top: 0.15rem;
  left: 0;
}
.comment-box-wrapper .box-inner {
  width: 100%;
  height: 0;
  flex: 1 1;
  position: relative;
  padding-top: 0.4rem;
}
.site-body {
  min-height: 70vh;
}
@media screen and (min-width: 992px) {
  .site-body.page-top-padding {
    padding-top: 3.4rem;
  }
  .site-body.page-top-padding.overide-page-pad {
    padding-top: 0;
  }
}
.shopping-cart {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear 0.5s, opacity 0.3s linear 0.5s;
}
.shopping-cart.open {
  transition: visibility 0.2s linear, opacity 0.3s linear 0.5s;
  visibility: visible;
  opacity: 1;
}
.shopping-cart.open .cart-area {
  transition: right 0.5s ease-in 0.3s;
  right: 0;
}
.shopping-cart .cart-area {
  position: absolute;
  top: 0;
  right: -505px;
  width: 500px;
  height: 100%;
  padding-top: 75px;
  transition: right 0.5s ease-out;
}
.shopping-cart .ca-wrpr {
  padding: 15px 10px 10px;
  width: 100%;
  height: 100%;
  border-left: 1px solid var(--text-secondary-a50);
  background: var(--header-color);
  overflow-y: auto;
}
.shopping-cart .container {
  position: relative;
}
.shopping-cart .close-icon {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 18px;
  height: 18px;
  line-height: 1;
  color: var(--text-primary);
  font-size: 1.3rem;
}
.shopping-cart .close-icon:hover {
  color: var(--accent-color);
}
.empty-cart-card .empty-cart-msg {
  text-align: center;
  color: var(--text-primary);
  font-weight: 600;
}
.empty-cart-card .empty-cart-grf {
  width: 300px;
  height: 300px;
  padding: 15px;
  margin: 30px auto;
}
.empty-cart-card .cart-links {
  margin-top: 15px;
}
.empty-cart-card .cart-links .cart-links-msg {
  text-align: center;
  color: var(--text-secondary);
  font-size: 12px;
}
.empty-cart-card .cart-links a, .empty-cart-card .cart-links a:hover {
  color: var(--text-secondary);
}
svg.EmptyCart {
  fill: var(--text-secondary);
  opacity: 0.5;
}
.shopping-cart-card {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.shopping-cart-card .cart-item-image img {
  width: 100%;
}
.shopping-cart-card .cart-item-name {
  color: var(--text-primary);
}
.shopping-cart-card .cart-item-total {
  color: var(--text-secondary);
  font-weight: 600;
  font-size: 19px;
}
.shopping-cart-card .cart-item-remove {
  position: absolute;
  top: 3px;
  right: 0;
  width: 18px;
  height: 18px;
  line-height: 1;
  color: var(--text-primary);
  font-size: 1rem;
}
.shopping-cart-card .cart-item-remove:hover {
  color: var(--accent-color);
}
.product-quantity {
  margin-top: 3px;
  font-size: 0.9rem;
  color: var(--text-primary);
}
.product-quantity .pr20 {
  margin-right: 20px;
}
.product-quantity .qty-num {
  margin: 0 8px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-secondary);
}
.product-quantity .btn-qty {
  font-size: 1.4rem;
  line-height: 1;
  position: relative;
  bottom: 2px;
  padding: 0;
  color: var(--text-secondary);
  /*
  color: $c-text-primary;
  width: 24px;
  height: 24px;
  background: $c-text-secondary;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  border-radius: 50%;
  padding-top: 1px;
  padding-left: 5px;
  margin:0 5px;
  line-height: 1;
  &.pq-min {
  	padding-top: 0px;
  	padding-left: 7px;
  }
  */
}
.product-quantity .btn-qty:hover {
  color: var(--text-primary);
}
.product-quantity .btn-qty.vt-disabld {
  color: var(--text-secondary-a50);
  cursor: default;
}
.static-quantity {
  margin-top: 3px;
  font-size: 0.9rem;
  color: rgba(var(--text-primary), 0.7);
}
.static-quantity .pr20 {
  margin-right: 7px;
}
.static-quantity .qty-num {
  margin: 0 8px;
  color: var(--text-primary);
}
.cart-summary-card {
  color: var(--text-primary);
  font-size: 12px;
  margin-bottom: 40px;
}
.cart-summary-card .summary-top-pad {
  padding-top: 10px;
}
.cart-summary-card .row {
  padding-bottom: 4px;
}
.cart-summary-card .row.summary-wrpr {
  padding-bottom: 15px;
}
.cart-summary-card .summary-border {
  padding-bottom: 6px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--text-secondary);
}
.cart-summary-card .summary-title h3 {
  font-weight: 600;
  font-size: 16px;
}
.cart-summary-card .summary-right {
  text-align: right;
}
.cart-summary-card .summary-total {
  font-weight: 600;
}
.cart-summary-card .sh-cart-btn {
  height: 51px;
  min-height: 51px;
}
.cart-summary-card .sh-cart-btn .vt-btn {
  position: absolute;
  right: 0;
}
.shopping-cart-content {
  width: 100%;
}
.shopping-cart-content .widget-title {
  margin-bottom: 30px;
}
.shopping-cart-content h2 {
  text-align: center;
}
.dotloader {
  background-color: var(--background);
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dotloader span {
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background-color: var(--accent-color);
  -webkit-animation: dotloaderAnimation 1.5s infinite;
          animation: dotloaderAnimation 1.5s infinite;
}
.dotloader span:nth-child(0) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.dotloader span:nth-child(1) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.dotloader span:nth-child(2) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
@-webkit-keyframes dotloaderAnimation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes dotloaderAnimation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.dotloader.background {
  background-color: var(--background-l17);
}
body.earth {
  font-family: "Open Sans", sans-serif !important;
  /* show and card css overridews */
  /* video grid listing overrides */
  /* Let's get this party started */
  /* Track */
  /* Handle */
  /* EPG */
}
body.earth .vt-input,
body.earth .plans-listing .plan-box,
body.earth .vt-select {
  font-family: "Open Sans", sans-serif;
}
body.earth .vt-btn {
  font-family: "Open Sans", sans-serif;
  border-radius: 100px;
  font-weight: 600;
  border: 0;
  min-width: 100px;
  font-size: 0.7rem;
  margin: 0 0.6rem 0 0;
  padding: 0.4rem 1.4rem;
  letter-spacing: 0rem;
}
@media screen and (max-width: 768px) {
  body.earth .vt-btn {
    min-width: 100px;
    font-size: 0.6rem;
    margin: 0;
    padding: 0.4rem 1.2rem;
    letter-spacing: 0rem;
  }
}
body.earth .vt-btn.btn-sm {
  font-size: 0.6rem;
  margin: 0;
  padding: 0.5rem 0.4rem;
  letter-spacing: 0rem;
}
body.earth .vt-btn.vt-play {
  position: relative;
  padding-left: 2.3rem;
}
body.earth .vt-btn.vt-play::before {
  position: absolute;
  font-size: 0.8rem;
  font-family: "Font Awesome 5 Free", sans-serif;
  top: 0.3rem;
  left: 1.2rem;
  content: "\F04B";
}
@media screen and (max-width: 768px) {
  body.earth .vt-btn.vt-play {
    padding-left: 2rem;
  }
  body.earth .vt-btn.vt-play::before {
    font-size: 0.7rem;
    top: 0.35rem;
    left: 0.9rem;
  }
}
body.earth .payment .card .cancel-button > .vt-btn {
  color: var(--accent-color) !important;
  font-size: 0.9rem;
  border: none !important;
  background: none !important;
}
body.earth .plans-listing .info_text .text-secondary {
  color: var(--accent-color) !important;
  font-size: 1rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
  body.earth .plans-listing .info_text .text-secondary {
    font-size: 0.85rem;
    margin-top: 0.6rem;
    margin-right: 0;
    text-align: center !important;
  }
}
body.earth .vt-btn-link {
  color: var(--text-primary) !important;
  border: 1px solid var(--text-primary-a50) !important;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 0.7rem;
}
body.earth .vt-btn-link:hover {
  color: var(--accent-color) !important;
}
@media screen and (max-width: 768px) {
  body.earth .vt-btn-link {
    font-size: 0.6rem;
  }
}
body.earth .widget-title h2 {
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif !important;
  color: var(--text-primary) !important;
  margin-bottom: 14px;
  margin-left: 10px;
}
@media screen and (min-width: 576px) {
  body.earth .container {
    max-width: 600px;
  }
}
@media screen and (min-width: 768px) {
  body.earth .container {
    max-width: 900px;
  }
}
@media screen and (min-width: 992px) {
  body.earth .container {
    max-width: 1075px;
  }
}
@media screen and (min-width: 1200px) {
  body.earth .container {
    max-width: 1250px;
  }
}
@media screen and (min-width: 768px) {
  body.earth .video-carousel .carousel-sm {
    display: block;
  }
}
body.earth .video-carousel .carousel-sm .carousel-control-next,
body.earth .video-carousel .carousel-sm .carousel-control-prev {
  width: 8%;
}
@media screen and (max-width: 768px) {
  body.earth .video-carousel .carousel-sm .carousel-control-next,
  body.earth .video-carousel .carousel-sm .carousel-control-prev {
    width: 13%;
  }
}
body.earth .show-card .primary-thumb {
  border-radius: 0.75rem;
}
body.earth .hybrid-list-card-l2 {
  margin: 0% 0.5%;
}
body.earth .hybrid-list-card-l2 .primary-thumb {
  border-radius: 0.75rem;
}
body.earth .video-grid-listing .widget-title {
  display: none;
}
body.earth .video-grid-listing .outer-wrap {
  padding-left: 23px;
  padding-right: 23px;
}
body.earth ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body.earth ::-webkit-scrollbar-thumb {
  background: var(--text-primary-a50);
}
body.earth ::-webkit-scrollbar-thumb:window-inactive {
  background: var(--text-primary-a10);
}
@media screen and (min-width: 992px) {
  body.earth .channel-epg-horizontal-listing .inner-wrpr {
    width: 70%;
    max-width: 1100px;
  }
}
body.earth .channel-epg-horizontal-listing .inner-wrpr .img-hldr {
  border-radius: 0.75rem;
  overflow: hidden;
}
body.earth .no-epg-card,
body.earth .channel-epg-card {
  background-color: var(--text-primary-a10);
}
body.earth .no-epg-card .txt-wrpr .time-txt::before,
body.earth .channel-epg-card .txt-wrpr .time-txt::before {
  top: 0.35rem;
}
body.earth .new-episode {
  top: 1px;
  right: 1px;
  border-top-right-radius: 0.65rem;
  border-bottom-left-radius: 0.65rem;
  padding: 0 8px;
  padding-top: 3px;
  min-width: 29px;
  height: 22px;
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  body.earth .new-episode {
    border-top-right-radius: 0.45rem;
    border-bottom-left-radius: 0.45rem;
    font-size: 0.5rem;
    height: 16px;
    padding-top: 2px;
  }
}
body.earth .hybrid-horizontal-listing .carousel-control-next,
body.earth .hybrid-horizontal-listing .carousel-control-prev {
  z-index: 2;
}
body.earth .hybrid-horizontal-listing .carousel-control-next span,
body.earth .hybrid-horizontal-listing .carousel-control-prev span {
  height: 66px;
}
body.earth .hybrid-horizontal-listing .content-wrpr .title {
  margin-top: 0.3rem;
  font-size: 0.9rem;
}
body.earth .live-video-details-card {
  margin: 0 4.37rem;
}
body.jupiter {
  font-family: "Poppins", sans-serif !important;
}
body.jupiter .widget-title h2 {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif !important;
  color: var(--accent-color);
}
body.jupiter .vt-btn,
body.jupiter .vt-input {
  font-family: "Poppins", sans-serif !important;
}
body.jupiter .video-horizontal-listing .widget-title,
body.jupiter .show-horizontal-listing .widget-title,
body.jupiter .movie-horizontal-listing .widget-title,
body.jupiter .hybrid-horizontal-listing .widget-title {
  position: relative;
}
body.jupiter .video-horizontal-listing .widget-title::before,
body.jupiter .show-horizontal-listing .widget-title::before,
body.jupiter .movie-horizontal-listing .widget-title::before,
body.jupiter .hybrid-horizontal-listing .widget-title::before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), var(--text-secondary-a50), rgba(0, 0, 0, 0));
  top: 55%;
  left: 0;
}
body.jupiter .video-horizontal-listing .widget-title h2,
body.jupiter .show-horizontal-listing .widget-title h2,
body.jupiter .movie-horizontal-listing .widget-title h2,
body.jupiter .hybrid-horizontal-listing .widget-title h2 {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  font-family: "Poppins", sans-serif !important;
  padding-top: 5px;
  margin: 0 auto 12px;
  text-align: center;
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 7px;
  padding-right: 7px;
  max-width: 80%;
  background-color: var(--background);
}
body.jupiter .vt-btn {
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
}
body.jupiter .search-form form div.input-group .search-box {
  border-radius: 8px;
  border: none;
  background-color: var(--text-secondary-a50);
  padding-right: 31px;
}
body.jupiter .search-form form div.input-group .search-button {
  background-color: rgba(0, 0, 0, 0);
  width: 33px;
}
@media screen and (min-width: 992px) {
  body.jupiter .movie-details-card {
    margin-bottom: 70px;
    min-height: 360px;
  }
}
body.jupiter .plans-listing .plan-box {
  font-family: "Poppins", sans-serif;
}
body.jupiter .site-header .app-logo, body.jupiter .site-header-v2 .app-logo {
  width: 130px;
  height: 33px;
}
body.jupiter .site-header .nav-item .nav-link.true-link:hover:after,
body.jupiter .site-header .nav-item .nav-link.true-link.active:after, body.jupiter .site-header-v2 .nav-item .nav-link.true-link:hover:after,
body.jupiter .site-header-v2 .nav-item .nav-link.true-link.active:after {
  display: none;
}
body.jupiter .site-header .nav-item .nav-link.true-link:hover,
body.jupiter .site-header .nav-item .nav-link.true-link.active, body.jupiter .site-header-v2 .nav-item .nav-link.true-link:hover,
body.jupiter .site-header-v2 .nav-item .nav-link.true-link.active {
  color: var(--accent-color);
}
@media screen and (min-width: 992px) {
  body.jupiter .site-header .vt-btn, body.jupiter .site-header-v2 .vt-btn {
    min-width: 55px;
    font-size: 0.7rem;
    margin: 0 6px;
    padding: 0.7rem 1.2rem;
  }
  body.jupiter .site-header .navbar-brand, body.jupiter .site-header-v2 .navbar-brand {
    margin-right: 2.4rem;
  }
  body.jupiter .site-header .app-logo, body.jupiter .site-header-v2 .app-logo {
    width: 200px;
    height: 51px;
  }
  body.jupiter .site-header .search-form .form-inline, body.jupiter .site-header-v2 .search-form .form-inline {
    position: relative;
    width: 260px;
  }
  body.jupiter .site-header .search-form form div.input-group, body.jupiter .site-header-v2 .search-form form div.input-group {
    width: 80%;
    margin: 0 6px;
    position: absolute;
    right: 0;
  }
  body.jupiter .site-header .search-form form div.input-group:focus-within, body.jupiter .site-header-v2 .search-form form div.input-group:focus-within {
    width: 100%;
  }
  body.jupiter .site-header .all-links, body.jupiter .site-header-v2 .all-links {
    min-width: auto;
    height: 41px;
    background-color: var(--text-secondary-a50);
    border-radius: 8px;
  }
  body.jupiter .site-header .all-links .nav-item .nav-link, body.jupiter .site-header-v2 .all-links .nav-item .nav-link {
    margin: 3px 3px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  body.jupiter .site-header .dropdown-menu, body.jupiter .site-header-v2 .dropdown-menu {
    background-color: var(--text-secondary-d30);
    border-radius: 8px;
  }
  body.jupiter .site-header .dropdown-menu a, body.jupiter .site-header .dropdown-menu span, body.jupiter .site-header-v2 .dropdown-menu a, body.jupiter .site-header-v2 .dropdown-menu span {
    font-weight: 600;
  }
  body.jupiter .site-header .dropdown-menu.sub-link, body.jupiter .site-header-v2 .dropdown-menu.sub-link {
    top: 146%;
  }
  body.jupiter .site-header .user-profile-button .dropdown-menu, body.jupiter .site-header-v2 .user-profile-button .dropdown-menu {
    left: -5.5em;
  }
}
@media screen and (min-width: 1200px) {
  body.jupiter .site-header ul.main-nav, body.jupiter .site-header-v2 ul.main-nav {
    display: block;
    margin: auto;
  }
  body.jupiter .site-header .all-links .nav-item .nav-link, body.jupiter .site-header-v2 .all-links .nav-item .nav-link {
    margin: 3px 10px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
body.jupiter .video-carousel .carousel-control-prev span::before,
body.jupiter .video-carousel .carousel-control-next span::before {
  padding-top: 16px;
  padding-left: 3px;
  display: block;
  width: 34px;
  height: 34px;
  background-color: var(--botton-color);
  border-radius: 8px;
  color: var(--botton-text-color);
  font-size: 1.8rem;
}
body.jupiter .carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 2px;
}
body.jupiter .cnhl.carousel-control-prev span,
body.jupiter .cnhl.carousel-control-next span,
body.jupiter .chl.carousel-control-prev span,
body.jupiter .chl.carousel-control-next span,
body.jupiter .mhl.carousel-control-prev span,
body.jupiter .mhl.carousel-control-next span,
body.jupiter .shl.carousel-control-prev span,
body.jupiter .shl.carousel-control-next span,
body.jupiter .vhl.carousel-control-prev span,
body.jupiter .vhl.carousel-control-next span {
  font-size: 28px;
}
body.jupiter .video-details .video-actions {
  background-color: var(--text-secondary-d30);
  padding: 10px;
  border-radius: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
body.jupiter .watch-list-cntrls .add-icon,
body.jupiter .add-watch-over .add-icon {
  background-color: var(--botton-color);
}
body.jupiter .watch-list-cntrls .add-icon svg,
body.jupiter .add-watch-over .add-icon svg {
  fill: var(--botton-text-color);
}
body.jupiter .movie-details-card .video-genre,
body.jupiter .movie-details-card .video-cast-crew,
body.jupiter .movie-details-card .video-cast-crew .sec-text {
  font-family: "Poppins", sans-serif !important;
}
@media only screen and (min-width: 1100px) {
  body.venus .navbar .search-form {
    width: 300px;
  }
}
body.venus .hybrid-horizontal-listing .widget-title {
  color: var(--accent-color);
}
body.venus .hybrid-grid-listing .widget-title {
  margin-bottom: 30px;
  color: var(--text-secondary);
}
body.venus .hybrid-details-card-l2 .left-col .description {
  color: var(--text-secondary);
  margin: 1rem 0;
  font-size: 0.85rem;
  line-height: 1.5;
}
body.venus .fb-google-login-button, body.venus .facebook-login-button {
  border-radius: 100px;
}
body.venus.made-tommy {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .widget-title h2 {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .vt-input {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .vt-btn {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .carousel-video-card .caption-txt div {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .plans-listing .plan-box {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .movie-details-card .video-genre {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .movie-details-card .video-cast-crew {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.venus.made-tommy .movie-details-card .video-cast-crew .sec-text {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif;
}
body.venus.made-tommy .search-form .suggestions .widget-title h2 {
  font-family: "made tommy", "Montserrat", "Roboto", sans-serif !important;
}
body.mars {
  font-family: "Poppins", sans-serif !important;
}
body.mars .vt-input,
body.mars .plans-listing .plan-box,
body.mars .vt-select {
  font-family: "Poppins", sans-serif;
}
body.mars .vt-btn {
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.7rem;
  margin: 0 0.6rem 0 0;
}
@media screen and (max-width: 768px) {
  body.mars .vt-btn {
    min-width: 100px;
    font-size: 0.6rem;
  }
}
body.mars .vt-btn.btn-sm {
  font-size: 0.6rem;
  margin: 0;
  padding: 0.6rem 0.4rem;
  letter-spacing: 0rem;
}
body.mars .vt-btn.vt-play {
  position: relative;
  padding-left: 2.3rem;
}
body.mars .vt-btn.vt-play::before {
  position: absolute;
  font-size: 0.8rem;
  font-family: "Font Awesome 5 Free", sans-serif;
  top: 0.3rem;
  left: 1.2rem;
  content: "\F04B";
}
@media screen and (max-width: 768px) {
  body.mars .vt-btn.vt-play {
    padding-left: 2rem;
  }
  body.mars .vt-btn.vt-play::before {
    font-size: 0.7rem;
    top: 0.35rem;
    left: 0.9rem;
  }
}
body.mars .widget-title h2 {
  font-size: 1.1rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 14px;
  margin-left: 0.2rem;
}
body.mars .hybrid-list-card-l2 .content-wrpr .title,
body.mars .hybrid-list-card-l4 .content-wrpr .title {
  font-size: 0.9rem;
}
body.mars .navbar .user-profile-button {
  margin-right: 0.4rem;
}
body.mars .navbar .search-form {
  margin-right: 1rem;
  min-width: 25rem;
  border-radius: 4px;
}
@media screen and (max-width: 1200px) {
  body.mars .navbar .search-form {
    min-width: 16rem;
  }
}
body.mars .navbar .search-box {
  border-radius: 4px !important;
}
body.mars .navbar .search-btn {
  border-radius: 4px;
}
body.mars .site-footer {
  margin-top: 3rem;
}
body.mars .register-form .terms-text {
  color: var(--text-secondary);
}
body.quadrado {
  font-family: "Prompt", sans-serif !important;
}
body.quadrado .vtn-info-cntainr-div .vtn-info-desc-txt div,
body.quadrado .vtn-webplyr-tltxt-container .vtn-webplyr-tltxt-title,
body.quadrado .vtn-webplyr-tltxt-container .vtn-webplyr-tltxt-views,
body.quadrado .vtn-webplyr-tltxt-container .vtn-webplyr-tltxt-category,
body.quadrado .vtn-share-cntainr-div .vtn-share-holder-div .vtn-share-share-text-div,
body.quadrado .vtn-share-cntainr-div .vtn-share-holder-div .vtn-share-copied-txt {
  font-family: "Prompt", sans-serif !important;
}
body.quadrado .vt-input,
body.quadrado .plans-listing .plan-box,
body.quadrado .vt-select {
  font-family: "Prompt", sans-serif !important;
}
body.quadrado .vt-btn {
  font-family: "Prompt", sans-serif !important;
  border-radius: 9px;
  font-weight: 700;
  line-height: 1;
  font-size: 1.2rem;
  margin: 0 0.6rem 0 0;
  padding: 0.7rem 1.2rem 0.7rem;
  letter-spacing: 0.03rem;
  margin-left: 0;
  margin-right: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
body.quadrado .vt-btn.icon-btn {
  padding-left: 2.05rem;
}
body.quadrado .vt-btn.icon-btn svg {
  top: 0.73rem;
  left: 0.8rem;
  width: 1.1rem;
}
body.quadrado .vt-btn.create-account-btn, body.quadrado .vt-btn.login-account-btn {
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  body.quadrado .vt-btn {
    border-radius: 8px;
    min-width: 100px;
    font-size: 0.8rem;
    padding: 0.5rem 1.2rem 0.4rem;
  }
  body.quadrado .vt-btn.icon-btn {
    padding-left: 1.85rem;
  }
  body.quadrado .vt-btn.icon-btn svg {
    width: 0.85rem;
    top: 0.34rem;
    left: 0.87rem;
  }
}
body.quadrado .vt-btn.btn-sm {
  border-radius: 5px;
  min-width: 0;
  font-size: 0.75rem;
  padding: 0.375rem 1rem 0.35rem;
  letter-spacing: 0;
}
body.quadrado .vt-btn.btn-sm.icon-btn {
  padding-left: 1.6rem;
}
body.quadrado .vt-btn.btn-sm.icon-btn svg {
  width: 0.7rem;
  top: 0.34rem;
  left: 0.7rem;
}
body.quadrado .vt-btn.btn-sm.width-50 {
  padding: 12px;
  font-size: 0.75rem;
  margin-bottom: 10px;
  width: 50%;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  body.quadrado .vt-btn.btn-sm.width-50 {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 768px) {
  body.quadrado .vt-btn.btn-sm {
    min-width: 70px;
    font-size: 0.65rem;
    padding: 0.37rem 0.7rem 0.2rem;
  }
  body.quadrado .vt-btn.btn-sm.icon-btn {
    padding-left: 1.4rem;
  }
  body.quadrado .vt-btn.btn-sm.icon-btn svg {
    width: 0.65rem;
    top: 0.3rem;
  }
}
body.quadrado .vt-btn.btn-md {
  border-radius: 5px;
  min-width: 0;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  letter-spacing: 0;
}
body.quadrado .vt-btn.btn-md.icon-btn {
  padding-left: 1.6rem;
}
body.quadrado .vt-btn.btn-md.icon-btn svg {
  width: 0.7rem;
  top: 0.34rem;
  left: 0.7rem;
}
body.quadrado .vt-btn.btn-md.width-50 {
  margin-bottom: 10px;
  width: 50%;
}
@media screen and (max-width: 768px) {
  body.quadrado .vt-btn.btn-md.width-50 {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 768px) {
  body.quadrado .vt-btn.btn-md {
    min-width: 70px;
    font-size: 0.65rem;
    padding: 0.37rem 0.7rem 0.2rem;
  }
  body.quadrado .vt-btn.btn-md.icon-btn {
    padding-left: 1.4rem;
  }
  body.quadrado .vt-btn.btn-md.icon-btn svg {
    width: 0.65rem;
    top: 0.3rem;
  }
}
body.quadrado .vt-btn.callout-btn-link {
  font-weight: 600;
}
body.quadrado .widget-title {
  margin-bottom: 1rem;
  color: var(--accent-color);
}
body.quadrado .widget-title h2, body.quadrado .widget-title h1, body.quadrado .widget-title h3 {
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Prompt", sans-serif;
  margin-left: 0;
  margin-bottom: 0;
  line-height: 1;
  text-transform: uppercase;
}
body.quadrado .hybrid-list-card-l2 .content-wrpr .title,
body.quadrado .hybrid-list-card-l4 .content-wrpr .title {
  font-size: 0.9rem;
}
body.quadrado .form-control::-webkit-input-placeholder {
  border: none;
  color: var(--text-secondary-d30);
}
body.quadrado .form-control::placeholder,
body.quadrado .form-control::-webkit-input-placeholder,
body.quadrado .form-control {
  border: none;
  color: var(--text-secondary-d30);
}
body.quadrado input[type=text],
body.quadrado input[type=email],
body.quadrado input[type=password],
body.quadrado input[type=phone] {
  border-bottom: 1px solid var(--text-primary);
  background-color: transparent;
  padding-left: 0rem;
}
body.quadrado input[type=text]:focus,
body.quadrado input[type=email]:focus,
body.quadrado input[type=password]:focus,
body.quadrado input[type=phone]:focus {
  outline: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  border-bottom: 1px solid var(--accent-color);
}
body.quadrado input[type=phone] {
  padding-left: 1rem;
}
body.quadrado select {
  border: none;
  border-bottom: 1px solid var(--text-primary);
  background-color: transparent;
  padding-left: 0rem;
}
body.quadrado select:focus {
  border-bottom: 1px solid var(--accent-color);
  outline: none;
}
body.quadrado .form-group {
  margin-bottom: 1.5rem;
}
body.quadrado .pf-form-l2 .pf-form-l2-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 3%;
  gap: 3%;
}
body.quadrado .pf-form-l2 .pf-form-l2-form .form-group {
  flex-basis: 45%;
}
body.quadrado .pf-form-l2 .pf-form-l2-form .form-group.form-action-button-container {
  flex-basis: 100%;
  margin-top: 1.5rem;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default {
  margin-bottom: 1.2rem;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default.plan-box-detailed {
  border: none;
  background-color: var(--background-l17);
  height: auto;
  border-radius: 0.3rem;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default.plan-box-detailed .plan-name {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default.plan-box-detailed .plan-description {
  font-size: 0.7rem;
  color: var(--text-secondary);
  margin: 1rem 0rem;
  text-align: left;
  padding: 0 0.8rem;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default.plan-box-detailed .plan-action-btns-container {
  margin: 1rem 0rem;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default.plan-box-detailed .plan-action-btns-container .plan-selection-btn {
  font-size: 0.75rem;
  padding: 1rem 1.7rem;
  border-radius: 0.2rem;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default.plan-box-detailed .plan-price,
body.quadrado .item-box-wrpr .item-box.plan-box-default.plan-box-detailed .plan-icon {
  position: unset;
  padding: 0.7rem;
  display: none;
}
body.quadrado .item-box-wrpr .item-box.plan-box-default .current-plan {
  position: unset;
}
body.quadrado .additional-plan-details .additional-plan-details-btn {
  font-size: 0.9rem;
  border-radius: 0.2rem;
  color: var(--accent-color);
}
@media screen and (min-width: 768px) {
  body.quadrado .container {
    max-width: 775px;
  }
}
@media screen and (min-width: 992px) {
  body.quadrado .container {
    max-width: 975px;
  }
}
@media screen and (min-width: 1200px) {
  body.quadrado .container {
    max-width: 1155px;
  }
}
@media screen and (min-width: 1350px) {
  body.quadrado .container {
    max-width: 1300px;
  }
}
@media screen and (min-width: 1550px) {
  body.quadrado .container {
    max-width: 1470px;
  }
}
@media screen and (min-width: 1850px) {
  body.quadrado .container {
    max-width: 1670px;
  }
}
body.quadrado .button-lyblynx span {
  width: 100%;
  text-align: center;
}
body.quadrado ::-webkit-scrollbar {
  width: 0.2em;
}
body.quadrado ::-webkit-scrollbar-track {
  background: var(--text-primary);
  border-radius: 100vw;
  margin-block: 0.4em;
}
body.quadrado ::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
  border: 0.25em var(--text-primary);
  border-radius: 100vw;
}
body.quadrado ::-webkit-scrollbar-thumb:hover {
  background: var(--accent-color);
}

body.quadrado.inter {
  font-family: "Inter", sans-serif !important;
}
body.quadrado.inter .vt-input,
body.quadrado.inter .plans-listing .plan-box,
body.quadrado.inter .vt-select {
  font-family: "Inter", sans-serif !important;
}
body.quadrado.inter .vt-btn {
  font-family: "Inter", sans-serif !important;
}
body.quadrado.inter .widget-title h2, body.quadrado.inter .widget-title h1, body.quadrado.inter .widget-title h3 {
  font-family: "Inter", sans-serif;
}
.search-form form div.input-group {
  width: 90%;
  margin: auto;
  transition: width 0.4s linear;
}
.search-form form div.input-group .search-button, .search-form form div.input-group .search-box {
  background-color: var(--background);
  border-radius: 50px;
  color: var(--text-primary);
}
.search-form form div.input-group .search-button:focus, .search-form form div.input-group .search-box:focus {
  box-shadow: none;
}
.search-form form div.input-group.in-page {
  width: 50%;
}
@media screen and (max-width: 992px) {
  .search-form form div.input-group.in-page {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .search-form form div.input-group.in-page {
    width: 85%;
  }
}
@media screen and (max-width: 992px) {
  .search-form form div.input-group {
    width: 70%;
  }
}
.search-form form div.input-group .search-box {
  border: 1px solid var(--text-secondary);
  border-radius: 30px !important;
  height: 36px;
  font-size: 0.85rem;
  padding-right: 36px;
}
@media screen and (min-width: 768px) {
  .search-form form div.input-group .search-box {
    margin-top: 5px;
    height: 41px;
  }
}
.search-form form div.input-group .search-button {
  height: 34px;
  width: 34px;
  position: absolute;
  z-index: 3;
  top: 1px;
  right: 0.3rem;
  padding: 0.2rem;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .search-form form div.input-group .search-button {
    height: 39px;
    width: 39px;
    top: 6px;
  }
}
.search-form form div.input-group .search-button svg {
  fill: var(--text-primary);
}
.search-form form div.input-group .search-list {
  width: 100%;
}
.search-form .suggestions {
  text-align: center;
  list-style-type: none;
  color: var(--text-primary);
}
.search-form .suggestions .widget-title {
  color: var(--accent-color);
}
.search-form .suggestions .widget-title h2 {
  font-size: 1.5rem;
  font-family: "Montserrat", "Roboto", sans-serif;
}

.search-form-V2 .container {
  max-width: 40%;
  margin: 0 auto;
  margin-top: 8rem;
}
.search-form-V2 .search-bar {
  min-width: 76%;
  height: 36px;
  border: none;
  background-color: rgb(52, 52, 52);
  text-align: justify;
  padding-left: 15px;
  color: white;
  outline: none;
}
.search-form-V2 .search-btn {
  width: 26%;
  height: 38px;
  margin-left: -29px;
  border: none;
  background-color: rgb(187, 61, 139);
  color: white;
  font-weight: bold;
  outline: none;
}
.search-form-V2 .search-btn:hover {
  background-color: rgb(167, 46, 121);
}
.search-form-V2 .search-bar:hover {
  background-color: rgb(59, 58, 58);
}
.search-form-V2 .search-card {
  color: rgb(255, 255, 255);
  text-underline-offset: 1px solid white;
  font-weight: 10;
  padding-top: 10px;
  padding-left: 20px;
  border-bottom: 1px solid white;
  max-width: 90;
}
.search-form-V2 .result-card {
  color: white;
  border: 0.2px grey solid;
}
.search-form-V2 .movie-poster {
  max-width: 100px;
}
.search-form-V2 .card-title {
  color: white;
  font-weight: normal;
}
.search-form-V2 .suggestions {
  color: white;
  text-decoration: none;
  list-style-type: none;
  font-size: 1rem;
  cursor: pointer;
  padding-top: 10px;
}
.search-form-V2 .suggestions li:hover {
  color: red;
}
.search-form-V2 .suggestion-box {
  border: 1px solid grey;
  border-top: none;
  padding-left: 1rem;
  box-sizing: border-box;
}
.vt-modal .modal-content {
  background-color: var(--surface-secondary);
  min-height: 400px;
  position: relative;
}
.vt-modal .modal-content .modal-header {
  border: none;
}
.vt-modal .modal-content .close-btn {
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  line-height: 0;
  cursor: pointer;
  z-index: 1;
}
.vt-modal .modal-content .close-btn svg {
  fill: var(--text-primary);
  width: 100%;
  height: 100%;
}
.vt-modal .modal-content .modal-body {
  padding: 2rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.vt-modal .modal-content .close {
  color: var(--accent-color);
}
.site-header {
  text-align: center;
  padding: 0 !important;
  border-bottom: 1px solid var(--text-secondary-a50);
  background: var(--header-color);
  width: 100%;
  transition: all 0.6s ease-out;
}
@media screen and (min-width: 992px) {
  .site-header {
    height: 75px;
  }
}
.site-header.scrolled {
  background: var(--background);
  min-height: 60px;
}
.site-header.scrolled .all-links {
  min-height: 60px;
}
.site-header .navbar-icon {
  position: relative;
  overflow: hidden;
}
.site-header .navbar-icon .vt-btn-like {
  position: relative;
}
.site-header .navbar-icon .vt-btn-like.vn-shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  -webkit-animation: shake 0.5s;
          animation: shake 0.5s;
  /* When the animation is finished, start again */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.site-header .navbar-icon .shop-icon {
  width: 50px;
  height: 50px;
  padding: 8px;
  color: var(--text-primary);
  text-align: center;
  line-height: 1.4;
  font-size: 1.6rem;
  margin-right: 8px;
}
.site-header .navbar-icon .shop-item-count {
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: 16px;
  height: 16px;
  background-color: var(--botton-color);
  border-radius: 50%;
  color: white;
  top: 5px;
  left: 21px;
}
.site-header .user-icon .vt.vt-usersolid {
  font-size: 30px;
  margin-left: 10px;
  color: var(--botton-color);
}
.site-header .user-profile-button .dd-name {
  display: none;
  color: var(--accent-color);
  padding: 0.25rem 0.4rem 0.5rem;
  margin-bottom: 6px;
  border-bottom: 1px solid var(--text-secondary-a50);
  cursor: default;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.site-header .user-profile-button .dd-name:hover {
  color: var(--accent-color);
}
.site-header .user-profile-button .dropdown-menu {
  width: 8rem;
  left: -6.7rem;
}
@media screen and (max-width: 992px) {
  .site-header .user-profile-button .dropdown-menu {
    width: auto;
  }
}
.site-header .user-profile-button .user-icon .user-name {
  font-size: 0.8rem;
  position: relative;
  bottom: 4px;
  color: var(--accent-color);
}
@media screen and (min-width: 992px) {
  .site-header .user-profile-button .user-icon .user-name {
    display: none;
  }
  .site-header .user-profile-button .dd-name {
    display: block;
  }
}
.site-header .explore-button {
  font-weight: 400;
}
.site-header .explore-button .vt.vt-browse {
  font-size: 2rem;
  margin-left: 5px;
  vertical-align: inherit;
}
.site-header .search-form {
  margin-top: 0;
}
.site-header .search-form form div.input-group:focus-within {
  width: 100%;
}
@media screen and (max-width: 992px) {
  .site-header .search-form {
    margin-top: 10px;
  }
  .site-header .search-form form div.input-group:focus-within {
    width: 80%;
  }
}
.site-header .search-form.suggestions {
  display: none;
}
.site-header .dropdown-menu {
  background-color: var(--header-color);
  text-align: center;
  top: 135%;
  left: -3.2rem;
}
@media screen and (min-width: 992px) {
  .site-header .dropdown-menu {
    width: 11.4rem;
  }
}
@media screen and (max-width: 992px) {
  .site-header .dropdown-menu {
    padding-top: 0;
    margin-top: 0;
  }
}
.site-header .dropdown-menu a,
.site-header .dropdown-menu span {
  color: var(--header-text-color);
  white-space: pre-wrap;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
  line-height: 1.2;
  margin-bottom: 0.2rem;
}
.site-header .dropdown-menu a:hover,
.site-header .dropdown-menu span:hover {
  color: var(--botton-color);
  background: none;
}
.site-header .dropdown-menu.sub-link {
  top: 156%;
}
.site-header .app-logo-lg {
  width: 200px;
}
@media screen and (max-width: 992px) {
  .site-header .app-logo-lg {
    width: 175px;
  }
}
@media screen and (max-width: 768px) {
  .site-header .app-logo-lg {
    width: 150px;
  }
}
.site-header .app-logo {
  width: 148px;
  height: 47px;
}
.site-header .navbar-toggler .navbar-toggler-icon {
  font-size: inherit;
  background: inherit;
}
.site-header .navbar-toggler .vt-menu:before {
  font-size: 30px;
  color: var(--header-text-color);
}
.site-header .all-links {
  transition: all 0.6s ease-out;
  min-width: 350px;
}
.site-header .all-links .nav-item {
  position: relative;
  float: left;
}
@media screen and (max-width: 992px) {
  .site-header .all-links .nav-item {
    margin-bottom: 5px;
  }
}
.site-header .all-links .nav-item .nav-link {
  font-size: 0.95rem;
  font-weight: 600;
  margin: auto 0;
  color: var(--header-text-color);
  line-height: 1.2;
}
.site-header .all-links .nav-item .nav-link.babylove {
  font-family: babylove, sans-serif;
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  line-height: 0.9;
  font-weight: 400;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .site-header .all-links .nav-item .nav-link.babylove {
    font-size: 1.1rem;
    line-height: 1;
  }
}
.site-header .all-links .nav-item .nav-link.true-link:hover:after, .site-header .all-links .nav-item .nav-link.true-link.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 50%;
  max-width: 50px;
  margin: auto;
  left: calc((100% - 50%) / 2);
  right: calc((100% - 50%) / 2);
  border-bottom: 3px solid var(--botton-color);
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}
.popup-backdrop.popup-backdrop-v2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: none;
}
.popup-backdrop.popup-backdrop-v2.open {
  display: block;
}
.popup-backdrop.popup-backdrop-v2 .popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  max-width: 37.5rem;
  background-color: var(--background-l17);
  border-radius: 0.2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  min-height: 10rem;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-header {
  padding: 1rem;
  border-bottom: 1px solid var(--text-secondary-d30);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-header .popup-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-primary);
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-header .popup-close {
  position: unset;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 1.81rem;
  height: 1.81rem;
  padding: 0;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-header .popup-close svg {
  fill: var(--text-secondary);
  width: 100%;
  height: 100%;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-header .popup-close:hover svg {
  fill: var(--text-primary);
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body {
  padding: 1rem;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .popup-description,
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .popup-subline,
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .popup-loader,
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .popup-footnote {
  font-size: 1rem;
  color: var(--text-secondary);
  text-align: left;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .popup-subline {
  font-size: 0.8rem;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .popup-alert {
  font-size: 0.8rem;
  color: var(--accent-color);
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .popup-footnote {
  font-size: 0.7rem;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .flexed-buttons {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 1rem;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-body .flexed-buttons .vt-btn {
  font-size: 1rem;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-footer {
  padding: 1rem;
}
.popup-backdrop.popup-backdrop-v2 .popup-container .popup-footer.space-between {
  display: flex;
  justify-content: space-between;
}
.site-header.earth {
  text-align: center;
  padding: 0;
  border-bottom: none;
  background: var(--header-color);
  width: 100%;
  transition: all 0.6s ease-out;
}
@media screen and (min-width: 992px) {
  .site-header.earth {
    height: 75px;
  }
}
.site-header.earth .navbar-brand {
  margin: 0;
}
.site-header.earth .navbar-icon {
  position: relative;
  overflow: hidden;
}
.site-header.earth .navbar-icon .vt-btn-like {
  position: relative;
}
.site-header.earth .navbar-icon .vt-btn-like.vn-shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  -webkit-animation: shake 0.5s;
          animation: shake 0.5s;
  /* When the animation is finished, start again */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.site-header.earth .navbar-icon .shop-icon {
  width: 50px;
  height: 50px;
  padding: 8px;
  color: var(--text-primary);
  text-align: center;
  line-height: 1.4;
  font-size: 1.6rem;
  margin-right: 8px;
}
.site-header.earth .navbar-icon .shop-item-count {
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 800;
  position: absolute;
  text-align: center;
  width: 16px;
  height: 16px;
  background-color: var(--header-text-color);
  border-radius: 50%;
  color: white;
  top: 5px;
  left: 21px;
}
.site-header.earth .user-icon .vt.vt-usersolid {
  font-size: 30px;
  margin-left: 10px;
}
.site-header.earth .user-profile-button .dropdown-menu {
  min-width: 8rem;
}
.site-header.earth .explore-button {
  font-weight: 400;
}
.site-header.earth .explore-button .vt.vt-browse {
  font-size: 2rem;
  margin-left: 5px;
  vertical-align: inherit;
}
.site-header.earth .search-form {
  margin-top: 0;
}
.site-header.earth .search-form form div.input-group:focus-within {
  width: 100%;
}
.site-header.earth .search-form .suggestions {
  display: none;
}
.site-header.earth .dropdown-menu {
  left: -2.8rem;
}
.site-header.earth .dropdown-menu a, .site-header.earth .dropdown-menu span {
  color: var(--text-secondary);
  cursor: pointer;
  font-weight: 800;
  font-size: 0.9rem;
}
.site-header.earth .dropdown-menu a:hover, .site-header.earth .dropdown-menu span:hover {
  color: var(--header-text-color);
}
.site-header.earth .app-logo {
  width: 150px;
  height: 64px;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .site-header.earth .app-logo {
    width: 117px;
    height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .site-header.earth .app-logo {
    width: 117px;
    height: 50px;
  }
}
@media screen and (max-width: 576px) {
  .site-header.earth .app-logo {
    width: 94px;
    height: 40px;
  }
}
.site-header.earth .navbar-toggler {
  margin-right: 10px;
}
.site-header.earth .navbar-toggler .navbar-toggler-icon {
  font-size: inherit;
  background: inherit;
}
.site-header.earth .navbar-toggler .vt-menu:before {
  font-size: 30px;
  color: var(--header-text-color);
}
.site-header.earth .all-links {
  transition: all 0.6s ease-out;
  min-width: 252px;
}
@media screen and (max-width: 992px) {
  .site-header.earth .all-links {
    margin-bottom: 10px;
  }
  .site-header.earth .all-links.add-links {
    margin-bottom: 20px;
  }
}
.site-header.earth .all-links.add-links {
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
  direction: ltr;
  padding: 0rem;
}
.site-header.earth .all-links.add-links .nav-item {
  float: right;
}
@media screen and (max-width: 992px) {
  .site-header.earth .all-links.add-links {
    display: flex;
  }
  .site-header.earth .all-links.add-links .nav-item {
    float: none;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
  }
  .site-header.earth .all-links.add-links .nav-item .nav-link.vt-icon {
    margin: auto;
    width: 33px;
  }
}
.site-header.earth .all-links .nav-item {
  position: relative;
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.site-header.earth .all-links .nav-item .nav-link {
  margin: auto 0;
  color: var(--text-secondary);
  line-height: 1.2;
  font-weight: 800;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site-header.earth .all-links .nav-item .nav-link:hover, .site-header.earth .all-links .nav-item .nav-link.active {
  color: var(--header-text-color);
}
.site-header.earth .all-links .nav-item .nav-link:hover:after, .site-header.earth .all-links .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 86%;
  max-width: 86%;
  left: 7%;
  right: 7%;
  border-bottom: 3px solid var(--botton-color);
}
@media screen and (max-width: 992px) {
  .site-header.earth .all-links .nav-item .nav-link:hover:after, .site-header.earth .all-links .nav-item .nav-link.active:after {
    bottom: 2px;
    max-width: 58px;
    border-width: 2px;
    margin-left: auto;
    margin-right: auto;
  }
}
.site-header.earth .all-links .nav-item .nav-link.vt-icon:after, .site-header.earth .all-links .nav-item .nav-link.vt-btn-link:after {
  width: 0;
  height: 0;
  border: none;
}
.site-header.earth .all-links .nav-item .nav-link.vt-icon {
  padding: 0.1rem 0 0;
  margin: 0 0.4rem;
  width: 33px;
  height: 33px;
  min-width: 33px;
  min-height: 33px;
}
.site-header.earth .all-links .nav-item .nav-link.vt-icon .vt-search {
  font-size: 2.3rem;
}
.site-header.earth .all-links .nav-item .nav-link.vt-btn-link {
  margin-top: 5px;
  font-size: 0.7rem;
  padding: 0.4rem 0.4rem;
  font-weight: 600;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .site-header.earth .all-links .nav-item {
    margin-right: 3px;
  }
  .site-header.earth .all-links .nav-item .nav-link {
    font-size: 0.93rem;
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}
.vtn-page-loader-anim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
}
.vtn-page-loader-anim .anim-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--accent-color);
  -webkit-animation: animate 4s linear;
          animation: animate 4s linear;
}
.vtn-page-loader-anim .anim-line.over {
  background-color: var(--text-primary-a50);
}
@-webkit-keyframes animate {
  0% {
    width: 0%;
  }
  40% {
    width: 70%;
  }
  80% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
@keyframes animate {
  0% {
    width: 0%;
  }
  40% {
    width: 70%;
  }
  80% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
.privacy_check {
  position: fixed;
  bottom: 0px;
  min-height: 70px;
  height: auto;
  width: 100%;
  background-color: var(--header-color);
  z-index: 6000;
  color: var(--header-text-color);
}
.privacy_check .row {
  margin: 20px 5px;
}
.privacy_check .label-hldr {
  margin-top: 13px;
}

.earth .privacy_check .label-hldr {
  margin-top: 4px;
}
.page-level-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: none;
}
.page-level-popup.open {
  display: block;
}
.page-level-popup .popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50%;
  background-color: var(--background-l17);
  border-radius: 0.2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  min-height: 10rem;
}
@media screen and (max-width: 768px) {
  .page-level-popup .popup-container {
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .page-level-popup .popup-container {
    width: 100%;
  }
}
.page-level-popup .popup-container .popup-header {
  padding: 1rem;
  border-bottom: 1px solid var(--text-secondary-d30);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-level-popup .popup-container .popup-header .popup-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-primary);
  justify-self: start;
}
.page-level-popup .popup-container .popup-header .popup-close {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-secondary);
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-self: end;
}
.page-level-popup .popup-container .popup-header .popup-close:hover {
  color: var(--text-primary);
}
.page-level-popup .popup-container .popup-body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.page-level-popup .popup-container .popup-body .popup-step-label {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}
.page-level-popup .popup-container .popup-body .social-share {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0 2rem 0;
  width: 100%;
  justify-content: center;
}
.page-level-popup .popup-container .popup-body .social-share .social-link-card {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 576px) {
  .page-level-popup .popup-container .popup-body .social-share .social-link-card {
    margin-right: 0.5rem;
    margin-bottom: 0.7rem;
    width: 6rem;
  }
}
@media screen and (max-width: 992px) {
  .page-level-popup .popup-container .popup-body .social-share .social-link-card {
    width: 5rem;
  }
}
.page-level-popup .popup-container .popup-body .social-share .social-link-card .icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
@media screen and (max-width: 576px) {
  .page-level-popup .popup-container .popup-body .social-share .social-link-card .icon-div {
    margin-bottom: 0.5rem;
  }
}
.page-level-popup .popup-container .popup-body .social-share .social-link-card .icon-div svg {
  fill: white;
}
@media screen and (max-width: 768px) {
  .page-level-popup .popup-container .popup-body .social-share .social-link-card .icon-div svg {
    max-width: 4.7rem;
    width: 4.7rem;
  }
}
.page-level-popup .popup-container .popup-body .social-share .social-link-card .social-link-label {
  color: var(--text-primary);
}
.page-level-popup .popup-container .popup-body .social-share:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .page-level-popup .popup-container .popup-body .social-share {
    grid-gap: 20px;
    gap: 20px;
    margin: 2rem 0 1rem 0;
  }
}
.page-level-popup .popup-container .popup-footer {
  padding: 1rem;
}
.jupiter .site-footer {
  margin-top: 40px;
  background-color: var(--text-secondary-d30);
}
.jupiter .site-footer .icon-link {
  color: var(--accent-color);
}
.jupiter .site-footer .icon-link::before {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 800;
  font-size: 1.2rem;
  content: "\F007";
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.jupiter .social-links {
  min-height: 100px;
  text-align: left;
}
.jupiter .social-links .all-links {
  padding-top: 15px;
}
.jupiter .social-links .all-links a.social-icon {
  margin-right: 34px;
}
.jupiter .social-links .app-logo img {
  width: 200px;
  height: 51px;
}
.jupiter .social-links a.social-icon {
  margin-right: 10px;
}
.jupiter .social-links a.social-icon::before {
  font-size: 2.4rem;
}
.jupiter .social-links a.social-icon.facebook::before {
  font-family: "Font Awesome 5 Brands", sans-serif;
  font-weight: 400;
  content: "\F082";
}
.jupiter .social-links a.social-icon.twitter::before {
  font-family: "Font Awesome 5 Brands", sans-serif;
  font-weight: 400;
  content: "\F081";
}
.jupiter .social-links a.social-icon.instagram::before {
  font-family: "Font Awesome 5 Brands", sans-serif;
  font-weight: 400;
  content: "\F16D";
}
.jupiter .social-links a.social-icon.youtube::before {
  font-family: "Font Awesome 5 Brands", sans-serif;
  font-weight: 400;
  content: "\F167";
}
@media screen and (max-width: 992px) {
  .jupiter .social-links {
    min-height: 80px;
  }
  .jupiter .social-links .all-links {
    min-height: 80px;
    padding-top: 13px;
  }
  .jupiter .social-links .all-links a.social-icon {
    margin-right: 20px;
  }
  .jupiter .social-links .all-links a.social-icon::before {
    font-size: 2.1rem;
  }
  .jupiter .social-links .app-logo img {
    width: 180px;
    height: 46px;
  }
}
@media screen and (max-width: 768px) {
  .jupiter .social-links {
    min-height: 60px;
  }
  .jupiter .social-links .all-links {
    min-height: 60px;
    padding-top: 10px;
  }
  .jupiter .social-links .all-links a.social-icon {
    float: left;
    margin-right: 15px;
  }
  .jupiter .social-links .all-links a.social-icon::before {
    font-size: 1.8rem;
  }
  .jupiter .social-links .app-logo img {
    width: 160px;
    height: 41px;
  }
}
@media screen and (max-width: 576px) {
  .jupiter .social-links .all-links a.social-icon {
    margin-right: 0;
    margin-right: 6px;
  }
}
.jupiter .site-footer-j {
  width: 100%;
  border-top: 1px solid var(--text-secondary-a50);
  text-align: center;
}
.jupiter .site-footer-j.all-links .d-inline-block {
  margin: auto auto;
}
.jupiter .site-footer-j.all-links span {
  font-size: 0.8rem;
  color: var(--text-primary);
}
.jupiter .site-footer-j.all-links span.site-name {
  color: var(--accent-color);
}
.jupiter .footer-links {
  color: var(--text-primary);
  text-align: left;
}
.jupiter .footer-links .all-links {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 0;
}
.jupiter .footer-links .all-links.two-clm {
  width: 50%;
}
.jupiter .footer-links .all-links .nav-item {
  margin-bottom: 15px;
  list-style: none;
  font-size: 0.9rem;
}
.jupiter .footer-links .all-links .nav-item a {
  color: var(--text-primary);
}
.jupiter .footer-links .all-links .nav-item a:hover {
  color: var(--accent-color);
}
.jupiter .footer-links .all-links .nav-item a.big {
  font-weight: 600;
}
.jupiter .footer-links .all-links .nav-item a.icon-link.play::before {
  font-family: "Font Awesome 5 Free", sans-serif;
  content: "\F04B";
  top: 0;
}
@media screen and (max-width: 768px) {
  .jupiter .footer-links .all-links {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .jupiter .footer-links .all-links .nav-item {
    margin-bottom: 9px;
  }
}
@media screen and (max-width: 576px) {
  .jupiter .footer-links .all-links {
    margin-top: 11px;
    margin-bottom: 11px;
  }
  .jupiter .footer-links .all-links .nav-item {
    margin-bottom: 5px;
  }
}
.earth .site-footer {
  background-color: var(--header-color);
  border: none;
  text-align: left;
  margin-top: 30px;
}
.earth .site-footer .widget-title h2 {
  margin-left: 0;
}
.earth .site-footer .bottom-line {
  border-bottom: 2px solid var(--text-secondary-a50);
}
.earth .site-footer .all-links {
  min-height: 70px;
}
.earth .site-footer .all-links a {
  margin-right: 24px;
}
.earth .site-footer .container {
  padding: 30px 30px 10px;
}
.earth .site-footer .all-links .nav-item {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
@media screen and (max-width: 992px) {
  .earth .site-footer .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.earth .site-footer .icon-link::before {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  content: "\F007";
  margin-right: 8px;
  position: relative;
  top: 2px;
}
@media screen and (max-width: 768px) {
  .earth .site-footer .all-links,
  .earth .site-footer .all-links a {
    font-size: 0.75rem;
  }
  .earth .site-footer .all-links {
    min-height: 50px;
  }
  .earth .site-footer .widget-title h2 {
    font-size: 1rem;
    margin-bottom: 10px;
    margin-left: 7px;
  }
}
@media screen and (max-width: 576px) {
  .earth .site-footer .widget-title h2 {
    font-size: 0.9rem;
    margin-bottom: 7px;
    margin-left: 5px;
  }
  .earth .site-footer .all-links a {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: 576px) {
  .earth .site-footer-e.all-links {
    margin: 10px 0 0;
  }
}
.earth .site-footer-e.all-links span {
  color: var(--text-secondary);
}
.earth .site-footer-e.all-links span span .app-logo,
.earth .site-footer-e.all-links span a .app-logo {
  width: 120px;
  height: 51px;
}
@media screen and (max-width: 768px) {
  .earth .site-footer-e.all-links span span .app-logo,
  .earth .site-footer-e.all-links span a .app-logo {
    width: 117px;
    height: 50px;
  }
}
@media screen and (max-width: 576px) {
  .earth .site-footer-e.all-links span span .app-logo,
  .earth .site-footer-e.all-links span a .app-logo {
    width: 94px;
    height: 40px;
  }
}
.earth .social-links {
  min-height: 100px;
}
.earth .social-links .all-links a.social-icon {
  margin-right: 34px;
}
.earth .social-links a.social-icon.facebook::before {
  font-family: "Font Awesome 5 Brands", sans-serif;
  content: "\F39E";
}
.earth .social-links a.social-icon.twitter::before {
  font-family: "Font Awesome 5 Brands", sans-serif;
  content: "\F099";
}
.earth .social-links a.social-icon.instagram::before {
  font-family: "Font Awesome 5 Brands", sans-serif;
  content: "\F16D";
}
@media screen and (max-width: 576px) {
  .earth .social-links .all-links a.social-icon {
    margin-right: 15px;
  }
}
.earth .footer-links .all-links {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 0;
  float: left;
}
.earth .footer-links .all-links.two-clm {
  width: 50%;
}
.earth .footer-links .all-links .nav-item {
  margin-bottom: 15px;
  list-style: none;
  font-size: 0.9rem;
}
.earth .footer-links .all-links .nav-item a {
  cursor: pointer;
  color: var(--text-secondary);
}
.earth .footer-links .all-links .nav-item a:hover {
  color: var(--text-primary);
}
.earth .footer-links .all-links .nav-item a.icon-link.play::before {
  font-family: "Font Awesome 5 Free", sans-serif;
  content: "\F04B";
  top: 0;
}
.earth .footer-links .all-links .nav-item.big {
  padding-top: 25px;
}
.earth .footer-links .all-links .nav-item.big a {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--text-primary);
}
@media screen and (max-width: 768px) {
  .earth .footer-links .all-links {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .earth .footer-links .all-links .nav-item {
    margin-bottom: 9px;
  }
}
@media screen and (max-width: 576px) {
  .earth .footer-links .all-links {
    margin-top: 11px;
    margin-bottom: 11px;
  }
  .earth .footer-links .all-links .nav-item {
    margin-bottom: 5px;
  }
  .earth .footer-links .all-links .nav-item.big {
    padding-top: 20px;
  }
  .earth .footer-links .all-links .nav-item.big a {
    font-size: 0.9rem;
  }
}
.site-footer {
  text-align: center;
  min-height: 100px;
  margin-top: 20px;
  border-top: 1px solid var(--text-secondary-a50);
  width: 100%;
}
.site-footer .all-links {
  min-height: 100px;
  font-size: 0.8rem;
}
.site-footer .all-links a, .site-footer .all-links span {
  margin: auto 0;
  color: var(--text-primary-a70);
  font-weight: 400;
  text-decoration: none;
}
.site-footer .all-links a .app-logo, .site-footer .all-links span .app-logo {
  width: 206px;
  height: 67px;
}
.site-footer .all-links a .app-link:hover, .site-footer .all-links span .app-link:hover {
  border-bottom: 2px solid var(--botton-color);
}
.site-footer .all-links .domain-name {
  color: var(--accent-color);
  margin-right: 0.3rem;
}
@media screen and (max-width: 576px) {
  .site-footer .all-links {
    margin: 10px auto;
  }
}
.side-menu {
  display: block;
  position: fixed;
  height: 100%;
  width: 18.75rem;
  z-index: 999;
  top: 0;
  left: -18.75rem;
  background-color: var(--surface-primary);
  transition: left 0.555s ease-in-out;
  padding-top: 5rem;
  padding-left: 1rem;
  overflow-y: auto;
}
.side-menu.display {
  display: block;
  left: 0;
}
.side-menu .contents {
  position: absolute;
  display: block;
  color: var(--accent-color);
}
.side-menu .side-menu-list {
  margin-bottom: 1rem;
}
@media screen and (max-width: 992px) {
  .side-menu {
    padding-top: 3.6rem;
  }
}
.side-menu .n-link-container,
.side-menu .h-link-container {
  margin-bottom: 0.7rem !important;
}
.side-menu .n-link-container .link,
.side-menu .h-link-container .link {
  color: var(--header-text-color);
  text-decoration: none;
}
.side-menu .n-link-container .link .svg-icon-wrapper svg,
.side-menu .h-link-container .link .svg-icon-wrapper svg {
  fill: var(--header-text-color);
  width: 1.3rem;
  min-width: 1.3rem;
  position: relative;
  bottom: 0.15rem;
}
.side-menu .n-link-container:hover .link,
.side-menu .h-link-container:hover .link {
  color: var(--botton-color);
  text-decoration: none;
}
.side-menu .n-link-container:hover .link.sub-link,
.side-menu .h-link-container:hover .link.sub-link {
  color: var(--text-primary);
}
.side-menu .n-link-container:hover .link .svg-icon-wrapper svg,
.side-menu .h-link-container:hover .link .svg-icon-wrapper svg {
  fill: var(--botton-color);
}
.fullpageloder {
  background-color: var(--background);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.fullpageloder.adopt-width {
  width: unset;
}
.fullpageloder .message {
  color: var(--text-secondary);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.auto-device-connect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.auto-device-connect .auth-success-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.auto-device-connect .auth-success-display h2 {
  color: var(--text-primary);
  margin-top: 40px;
  margin-bottom: 40px;
}
